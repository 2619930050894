import React from "react";


function ErrorPages() {
    return (

                <div className="py-[100px] text-center">
                    <h3 className="capitalize ">page not found</h3>
                </div>


    );
}

export default ErrorPages;
